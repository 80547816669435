<template>
  <div style="background-color: #ffffff; height: 100%">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave('PREPARE')"
      @head-submit="headSave('SUBMIT')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">

        <el-form
          ref="addForm"
          :model="addForm"
          :rules="rules"
          label-width="120px"
        >
          <div class="formTopic">基本信息</div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="事件编号" prop="eventCode">
                <el-input
                  placeholder="请输入事件编号"
                  v-model="addForm.eventCode"
                  :disabled="
                    isAutomatic || pageDisabled || addForm.id || formId
                  "
                >
                  <span slot="append">
                    自动生成
                    <el-switch
                      :disabled="pageDisabled || addForm.id || formId"
                      v-model="isAutomatic"
                      active-color="#13ce66"
                      @change="handleSwitch"
                    >
                    </el-switch>
                  </span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="事件标题" prop="title">
                <el-input
                  placeholder="请输入事件标题"
                  v-model="addForm.title"
                  maxlength="125"
                  show-word-limit
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发生地点" prop="location">
                <el-input
                  placeholder="请输入发生地点"
                  v-model="addForm.location"
                  maxlength="125"
                  show-word-limit
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="上报人" prop="userName">
                <el-input
                  placeholder="请输入上报人"
                  suffixIcon="el-icon-search"
                  v-model="addForm.userName"
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                  @focus="selectPeople"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发生时间" prop="eventTime">
                <el-date-picker
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="addForm.eventTime"
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                  type="datetime"
                  placeholder="选择发生时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="起因、性质、经过" prop="content">
                <el-input
                  placeholder="请输入事故发生的简要经过和发生的原因"
                  type="textarea"
                  v-model="addForm.content"
                  maxlength="255"
                  show-word-limit
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="污染物情况" prop="pollution">
                <el-input
                  placeholder="请输入污染物情况"
                  type="textarea"
                  maxlength="255"
                  show-word-limit
                  v-model="addForm.pollution"
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="人员环境影响" prop="effect">
                <el-input
                  placeholder="请输入人员环境影响"
                  type="textarea"
                  maxlength="255"
                  show-word-limit
                  v-model="addForm.effect"
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="处置措施" prop="treatment">
                <el-input
                  placeholder="请输入处置措施"
                  type="textarea"
                  maxlength="255"
                  show-word-limit
                  v-model="addForm.treatment"
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="现场情况" prop="files">
                <el-upload
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                  :class="{
                    uoloadSty: showImg,
                    disUoloadSty: noneUploadImgVideo,
                  }"
                  :action="'/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform'"
                  list-type="picture-card"
                  accept=".jpg,.png,.mp4"
                  :limit="9"
                  placeholder="请上传图片视频"
                  :file-list="addForm.files"
                  :headers="headers"
                  :on-success="
                    (response, file, fileList) => {
                      return uploadSuccess(response, file, fileList, 'files');
                    }
                  "
                  :on-remove="
                    (file, fileList) => {
                      return handleRemove(file, fileList, 'files');
                    }
                  "
                  :on-preview="handlePreview"
                  :on-exceed="uploadExceed"
                  :on-change="uploadChange"
                  :before-upload="uploadBeImgVideofore"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">
                    图片支持*.jpg/*.png格式,文件大小不超过5M;视频支持*MP4格式,文件大小不超过20M
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="处置报告" prop="dealingReport">
                <el-upload
                  :disabled="pageDisabled || onlyBaseInfoDisabled"
                  :class="{ uoloadSty: showImg, disUoloadSty: noneDealingReport }"
                  action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                  accept=".doc,.docx,.pdf,.zip"
                  :limit="1"
                  :file-list="dealingReport"
                  :headers="headers"
                  :on-preview="handleFilePreview"
                  :on-success="
                    (response, file, fileList) => {
                      return uploadSuccess(
                        response,
                        file,
                        fileList,
                        'dealingReport'
                      );
                    }
                  "
                  :on-remove="
                    (file, fileList) => {
                      return handleRemove(file, fileList, 'dealingReport');
                    }
                  "
                  :on-exceed="uploadExceed"
                  :before-upload="handleBeforeUpload"
                >
                  <el-button size="small"
                    ><i class="el-icon-upload2"></i> 上传文件</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    支持扩展名: .zip,.doc,.docx,.pdf
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <template
            v-if="
              addForm.status || formType == 'investigate' || formType == 'view'
            "
          >
            <div class="formTopic">事件调查结果</div>
            <el-row>
              <el-col :span="8">
                <el-form-item label="事件类别" prop="eventType">
                  <el-select
                    v-model="addForm.eventType"
                    placeholder="请选择事件类别"
                    :disabled="pageDisabled"
                  >
                    <el-option
                      v-for="item in eqEventType"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input
                    placeholder="请输入事件类别"
                    v-model="addForm.eventType"
                    :disabled="pageDisabled"
                  ></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="事件等级" prop="eventLevel">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      flex-wrap: nowrap;
                    "
                  >
                    <el-select
                      v-model="addForm.eventLevel"
                      placeholder="请选择事件等级"
                      :disabled="pageDisabled"
                    >
                      <el-option
                        v-for="item in eqEventLevel"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey"
                      >
                      </el-option>
                    </el-select>
                    <div style="margin-left: 5px">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        placement="right-start"
                      >
                        <span slot="content"
                          >（一）特别重大（Ⅰ级）突发环境事件：
                          1.因环境污染直接导致30人以上死亡或100人以上中毒或重伤的；
                          2.因环境污染疏散、转移人员5万人以上的；
                          3.因环境污染造成直接经济损失1亿元以上的；
                          4.因环境污染造成区域生态功能丧失或该区域国家重点保护物种灭绝的；
                          5.因环境污染造成设区的市级以上城市集中式饮用水水源地取水中断的；
                          6.I、II类放射源丢失、被盗、失控并造成大范围严重辐射污染后果的；放射性同位素和射线装置失控导致3人以上急性死亡的；放射性物质泄漏，造成大范围辐射污染后果的；
                          7.造成重大跨国境影响的境内突发环境事件。
                          （二）重大（Ⅱ级）突发环境事件：
                          1.因环境污染直接导致10人以上30人以下死亡或50人以上100人以下中毒或重伤的；
                          2.因环境污染疏散、转移人员1万人以上5万人以下的；
                          3.因环境污染造成直接经济损失2000万元以上1亿元以下的；
                          4.因环境污染造成区域生态功能部分丧失或该区域国家重点保护野生动植物种群大批死亡的；
                          5.因环境污染造成县级城市集中式饮用水水源地取水中断的；
                          6.Ⅰ、Ⅱ类放射源丢失、被盗的；放射性同位素和射线装置失控导致3人以下急性死亡或者10人以上急性重度放射病、局部器官残疾的；放射性物质泄漏，造成较大范围辐射污染后果的；
                          7.造成跨省级行政区域影响的突发环境事件。
                          （三）较大（Ⅲ级）突发环境事件：
                          1.因环境污染直接导致3人以上10人以下死亡或10人以上50人以下中毒或重伤的；
                          2.因环境污染疏散、转移人员5000人以上1万人以下的；
                          3.因环境污染造成直接经济损失500万元以上2000万元以下的；
                          4.因环境污染造成国家重点保护的动植物物种受到破坏的；
                          5.因环境污染造成乡镇集中式饮用水水源地取水中断的；
                          6.Ⅲ类放射源丢失、被盗的；放射性同位素和射线装置失控导致10人以下急性重度放射病、局部器官残疾的；放射性物质泄漏，造成小范围辐射污染后果的；
                          7.造成跨设区的市级行政区域影响的突发环境事件。
                          （四）一般（Ⅳ级）突发环境事件：
                          1.环境污染直接导致3人以下死亡或10人以下中毒或重伤的；
                          2.因环境污染疏散、转移人员5000人以下的；
                          3.因环境污染造成直接经济损失500万元以下的；
                          4.因环境污染造成跨县级行政区域纠纷，引起一般性群体影响的；
                          5.Ⅳ、Ⅴ类放射源丢失、被盗的；放射性同位素和射线装置失控导致人员受到超过年剂量限值的照射的；放射性物质泄漏，造成厂区内或设施内局部辐射污染后果的；铀矿冶、伴生矿超标排放，造成环境辐射污染后果的；
                          6.对环境造成一定影响，尚未达到较大突发环境事件级别的。
                          本款所称“以上”包括本数，“以下”不包括本数”</span
                        >
                        <i class="el-icon-question" style="font-size: 16px"></i>
                      </el-tooltip>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="直接经济损失" prop="lossFa">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      flex-wrap: nowrap;
                    "
                  >
                    <el-input
                      placeholder="请输入直接经济损失"
                      v-model="addForm.lossFa"
                      :disabled="pageDisabled"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    >
                      <span slot="suffix">万元</span>
                    </el-input>
                    <!-- <span style="display: block; text-align: right; width: 50px"
                      >万元</span
                    > -->
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="死亡人数" prop="lossDeath">
                  <el-input-number
                    style="width: 100%"
                    :disabled="pageDisabled"
                    v-model="addForm.lossDeath"
                    :min="0"
                    :max="999999"
                    label="死亡人数"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="重伤人数" prop="lossSerious">
                  <el-input-number
                    style="width: 100%"
                    :disabled="pageDisabled"
                    v-model="addForm.lossSerious"
                    :min="0"
                    :max="999999"
                    label="重伤人数"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="轻伤人数" prop="lossMinor">
                  <el-input-number
                    style="width: 100%"
                    :disabled="pageDisabled"
                    v-model="addForm.lossMinor"
                    :min="0"
                    :max="999999"
                    label="轻伤人数"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="其他损失" prop="lossOther">
                  <el-input
                    placeholder="请输入其他损失"
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                    v-model="addForm.lossOther"
                    :disabled="pageDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="根本原因" prop="reason">
                  <el-input
                    placeholder="请输入根本原因"
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                    v-model="addForm.reason"
                    :disabled="pageDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="事故详细经过" prop="accDesc">
                  <el-input
                    placeholder="请输入事故详细经过"
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                    v-model="addForm.accDesc"
                    :disabled="pageDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="责任认定及处理建议" prop="accResp">
                  <el-input
                    placeholder="请输入责任认定及处理建议"
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                    v-model="addForm.accResp"
                    :disabled="pageDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="整改措施" prop="suggustTreatment">
                  <el-input
                    placeholder="请输入整改措施"
                    type="textarea"
                    maxlength="255"
                    v-model="addForm.suggustTreatment"
                    :disabled="pageDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="调查报告" prop="reportDocUrl">
                  <el-upload
                    :disabled="pageDisabled"
                    :class="{
                      uoloadSty: showImg,
                      disUoloadSty: noneReportDocUrl,
                    }"
                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                    accept=".doc,.docx,.pdf,.zip"
                    :limit="1"
                    :file-list="reportDocUrl"
                    :headers="headers"
                    :on-success="
                      (response, file, fileList) => {
                        return uploadSuccess(
                          response,
                          file,
                          fileList,
                          'reportDocUrl'
                        );
                      }
                    "
                    :on-remove="
                      (file, fileList) => {
                        return handleRemove(file, fileList, 'reportDocUrl');
                      }
                    "
                    :on-exceed="uploadExceed"
                    :on-preview="handleFilePreview"
                    :before-upload="handleBeforeUpload"
                  >
                    <el-button size="small"
                      ><i class="el-icon-upload2"></i> 上传文件</el-button
                    >
                    <div slot="tip" class="el-upload__tip">
                      支持扩展名: .zip,.doc,.docx,.pdf
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-form>
      </div>
      <el-dialog
        title="人员选择"
        :visible.sync="deptShow"
        width="80%"
        top="8vh"
      >
        <UserDetpDialog
          ref="UserDetpDialog"
          @select-data="selectPeopleData"
        ></UserDetpDialog>
      </el-dialog>
      <el-dialog
        title="预览"
        :visible.sync="dialogVisible"
        append-to-body
        top="5vh"
        custom-class="customDialog"
        v-if="dialogVisible"
      >
        <img
          style="width: 100%; height: 100%; object-fit: contain"
          :src="dialogImageUrl"
          alt=""
          v-if="uploadType == 'img'"
        />
        <video
          style="width: 100%; height: 100%; object-fit: contain"
          autoplay
          :src="dialogVideoUrl"
          v-if="uploadType == 'video'"
          controls="controls"
        ></video>
      </el-dialog>
      <el-dialog
        v-dialog-drag
        title="附件预览"
        :modal="true"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :visible.sync="showFileDialog"
        width="70%"
      >
        <iframe
          ref="fileIframe"
          :src="fileUrl"
          frameborder="0"
          style="width: 100%; height: 500px"
        ></iframe>
        <span slot="footer">
          <el-button size="small" @click="showFileDialog = false"
            >取消</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";

import { getToken } from "@/util/auth";
import { dateFormat } from "@/util/date";
import * as API from "@/api/environmentProtect/environmentalEmergencies";
import { getPageCode } from "@/api/system/serialNumber";
import { dictionaryBiz } from "@/api/reportTasks";
import website from "@/config/website";
import { mapGetters } from "vuex";
export default {
  components: {
    HeadLayout,
    UserDetpDialog,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else {
        if (/^\d{1,9}(\.\d{1,2})?$/.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确值"));
        }
      }
    };
    return {
      headTitle: "",
      formType: "",
      addForm: {
        eventCode: "",
        title: "",
        location: "",
        userName: "",
        userId: "",
        eventTime: "",
        content: "",
        pollution: "",
        effect: "",
        treatment: "",
        files: [],
        eventType: "1",
        eventLevel: "",
        lossFa: "0",
        lossDeath: 0,
        lossSerious: 0,
        lossMinor: 0,
        lossOther: "",
        reason: "",
        accDesc: "",
        accResp: "",
        suggustTreatment: "",
        dealingReport: "",
        reportDocUrl: "",
        orgId: "",
        orgCode: "",
        status: 0,
      },
      rules: {
        eventCode: [
          { required: true, message: "请输入事件编号", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        location: [
          { required: true, message: "请输入发生地点", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请选择上报人", trigger: "change" },
        ],
        eventTime: [
          { required: true, message: "请选择发生时间", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入简要经过和原因", trigger: "blur" },
        ],
        pollution: [
          { required: true, message: "请输入污染物情况", trigger: "blur" },
        ],
        treatment: [
          { required: true, message: "请输入处置措施", trigger: "blur" },
        ],
        eventType: [
          { required: true, message: "请选择事件类别", trigger: "change" },
        ],
        eventLevel: [
          { required: true, message: "请选择事件等级", trigger: "change" },
        ],
        lossFa: [{ required: false, validator: validatePass, trigger: "blur" }],
        reason: [
          { required: true, message: "请输入根本原因", trigger: "blur" },
        ],
      },
      fileList: [],
      isAutomatic: true,
      dialogVisible: false,
      dialogImageUrl: "", // 预览图片路径
      dialogVideoUrl: "", // 预览视频路径
      uploadType: "img", // 默认是图片 img video 是视频的查看方式
      propsHttp: {
        url: "link",
        name: "name",
        res: "data",
      },
      deptShow: false,
      eqEventLevel: [],
      eqEventType: [],
      showFileDialog: false,
      fileUrl: "",
      showImg: true, //默认展示
      noneUploadImgVideo: false, //
      noneUploadFile: false,
      noneDealingReport: false,
      noneReportDocUrl: false,
      dealingReport: [],
      reportDocUrl: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    pageDisabled() {
      // 如果查看 或者 提交状态（未执行）则禁用 已执行禁用
      if (this.formType == "view") {
        return true;
      } else {
        return false;
      }
    },
    onlyBaseInfoDisabled() {
      // 执行调查进入此页面 基础信息表单需要被禁用
      if (this.formType == "investigate") {
        return true;
      } else {
        return false;
      }
    },
    headBtnOptions() {
      let result = [];
      if (
        ["add", "edit", "investigate"].includes(this.formType) &&
        !this.pageDisabled
      ) {
        if (!this.addForm.status) {
          result.push({
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          });
        }

        result.push({
          label: "提交",
          emit: "head-submit",
          type: "button",
          btnOptType: "refer",
        });
      }

      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },

  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化新增/编辑/查看页面
    async init() {
      let type = this.$route.query.type;
      this.formType = type;
      this.headTitle = "突发环境事件";
      if (this.formType == "add") {
        this.addForm.userId = this.userInfo.user_id;
        this.addForm.userName = this.userInfo.real_name;
        (this.addForm.eventTime = dateFormat(
          new Date(),
          "yyyy-MM-dd hh:mm:ss"
        )),
          await this.handleSwitch();
      } else if (this.formType == "edit") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);

      } else if (this.formType == "view") {
        this.formId = this.$route.query.id;
        this.showImg = false;
        this.noneUploadImgVideo = true;
        // this.noneUploadFile = true;
        this.noneDealingReport = true;
        this.noneReportDocUrl = true;
        this.getDetails(this.formId);
      } else if (this.formType == "investigate") {
        this.showImg = false;
        this.noneUploadImgVideo = true;
        this.noneDealingReport = true;
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      }

      dictionaryBiz("ep_event_level").then((res) => {
        this.eqEventLevel = res.data.data;
      });
      dictionaryBiz("ep_event_type").then((res) => {
        this.eqEventType = res.data.data;
      });
    },
    // 基本信息预案编号是否自动生成
    async handleSwitch() {
      if (this.isAutomatic) {
        await this.getPageCode();
      } else {
        this.addForm.eventCode = "";
      }
    },
    async getPageCode() {
      // 获取编码
      await getPageCode({ ruleCode: "event_code" }).then((res) => {
        this.$set(this.addForm, "eventCode", res.data.data || "");
        this.$forceUpdate();
      });
    },

    selectPeopleData(row, deptId, treeDeptName) {
      this.addForm.userId = row.id;
      this.addForm.userName = row.realName;
      this.deptShow = false;
    },

    selectPeople() {
      this.deptShow = true;
    },

    headSave(status) {
      if (this.formType == "add" && status == "SUBMIT" && !this.formId) {
        this.$message.warning("先保存表单内容为草稿状态后才可提交");
        return false;
      }
      this.saveOrSubmitFor(status);
    },
    // 保存提交改变状态
    saveOrSubmitFor(type) {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.saveLoading = true;

          if (type == "PREPARE") {
            this.addForm.status = 0;
          } else {
            this.addForm.status = this.addForm.status ? 2 : 1;
          }

          if (
            (this.formType == "add" && type == "SUBMIT" && this.formId) ||
            (type == "PREPARE" && this.formId) ||
            this.formType == "edit" ||
            this.formType == "investigate"
          ) {
            this.addForm.id = this.formId;
          }
          this.addForm.orgId = this.userInfo.dept_id;
          this.addForm.orgCode = this.userInfo.dept_id;
          const params = JSON.parse(JSON.stringify(this.addForm));
          params.files = JSON.stringify(params.files);
          // params.dealingReport = JSON.stringify(params.dealingReport);
          // params.reportDocUrl = JSON.stringify(params.reportDocUrl);
          params.reportDocUrl = JSON.stringify(this.reportDocUrl);
          params.dealingReport = JSON.stringify(this.dealingReport);
          // params.eventTime = dateFormat(params.eventTime, "yyyy-MM-dd hh:mm:ss"),
          API.epeventSubmit(params)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code == 200) {
                this.formId = data.id;
                this.getDetails(this.formId);
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                if (data.status == 2) this.formType = "view";
              } else {
                this.$message.error(msg);
              }
              this.saveLoading = false;
            })
            .catch((err) => {});
        }
      });
    },

    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 获取详情
    getDetails(id) {
      API.epeventDetail({ id }).then((res) => {
        if (res.data.code == 200) {
          const { data } = res.data;

          Object.keys(this.addForm).forEach((key) => {
            if (key == "files") {
              this.addForm[key] = JSON.parse(data[key]);
            } else if (key == "reportDocUrl" || key == "dealingReport") {
              this[key] = JSON.parse(data[key]);
            } else {
              this.addForm[key] = data[key];
            }
          });

        }
      });
    },

    // 判断文件是否为video
    isJudgeVideo(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileName == "mp4" || fileName == "MP4") {
        //视频附件，获取第一帧画面作为 封面展示
        return true;
      } else {
        return false;
      }
    },
    /**
     * 获取视频第一帧作为回显封面
     */
    getVideoCover(file) {
      let _self = this;
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = file.url; // url地址 url跟 视频流是一样的
      file.url = require("@/assets/images/videoLoading.gif"); //设置默认封面，videoDefCover 为预制的默认封面，不需要可去除或替换成自己的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        _self.$set(file, "url", _videoFirstimgsrc); //重置文件的url为当前截取的封面，用于 el-upload展示
        video.remove();
        canvas.remove();
      };
    },
    // 上传成功
    uploadSuccess(response, file, fileList, fieldName) {
      // fieldName 表单中向后端传递的字段名

      if (fieldName == "files") {
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            item.response.data.url = item.response.data.link;
            return item.response.data;
          } else {
            return item;
          }
        });
        let data = response.data;
        data.url = response.data.link;
        if (this.isJudgeVideo(data)) {
          //视频附件，获取第一帧画面作为 封面展示
          this.getVideoCover(data);
        }
      } else {
        this[fieldName] = fileList.map((item) => {
          if (item.response) {
            // item.response.data.url = item.response.data.link;
            // item.response.data.name = item.response.data.originalName
            // return item.response.data;
            return {
              url: item.response.data.link,
              link: item.response.data.link,
              name: item.response.data.originalName,
              // uid: item.response.data.uid,
              attachId: item.response.data.attachId,
              domain: item.response.data.domain,
            };
          } else {
            return item;
          }
        });
      }
    },
    // 上传失败回调
    handleError() {},
    // 文件预览
    handlePreview(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "mp4" ||
        fileName == "MP4"
      ) {
        this.dialogVisible = true;
        // 如果是视频
        if (fileName == "mp4" || fileName == "MP4") {
          this.uploadType = "video";
          this.dialogVideoUrl = file.link;
        } else {
          this.uploadType = "img";
          this.dialogImageUrl = file.link;
        }
      }
    },
    // 删除文件
    handleRemove(file, fileList, fieldName) {
      if (fieldName == "files") {
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
      } else {
        this[fieldName] = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
      }
      // if (fileList.length == 0) {
      //   this.$refs.addForm.validateField("fileList");
      // }
    },
    // 文件上传超出限制回调
    uploadExceed(files, fileList) {
      this.$message.error("超出上传限制文件数量");
    },
    // 上传之前的函数
    handleBeforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length).toLowerCase();
      let arr = [".zip", ".doc", ".docx", ".pdf"];
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
        return false;
      }
      if (!arr.includes(lastName)) {
        this.$message.warning("不支持上传此类型文件");
        return false;
      }
    },
    // 图片视频上传前的回调
    uploadBeImgVideofore(file) {
      const isJPG = file.type === "image/jpg" || "image/png" || "video/mp4";
      let maxNum = file.type == "video/mp4" ? 20 : 5;
      const isLt2M = file.size / 1024 / 1024 < maxNum;

      if (!isJPG) {
        this.$message.error("图片仅支持*.jpg/*.png格式;视频仅支持*MP4格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不超过5M;视频大小不超过20M!");
      }
      return isJPG && isLt2M;
    },

    // 文件改变时候修改提示信息
    uploadChange(uploadFile, fileList) {
      if (fileList.length !== 0) {
        this.$refs.addForm.validateField("fileList");
      }
    },
    handleFilePreview(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.url));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}
.formTopic {
  // margin: 0 12px;
  margin-bottom: 12px;

  // width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.disUoloadSty {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
  ::v-deep .el-upload__tip {
    display: none;
  }
  ::v-deep .el-upload--text {
    display: none;
  }
}
</style>
